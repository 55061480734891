import {
  AlertOutlined,
  ApartmentOutlined,
  BarChartOutlined,
  BorderInnerOutlined,
  FileSearchOutlined,
  PieChartOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "titleNursing",
    path: "",
    title: "nursing",
    breadcrumb: false,
    submenu: [
      {
        key: "eventMonitor",
        path: `${APP_PREFIX_PATH}/monitor-de-eventos`,
        title: "eventMonitor",
        icon: AlertOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboardCalls",
        path: `${APP_PREFIX_PATH}/dashboard/chamados`,
        title: "dashboardCalls",
        icon: PieChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "reports",
        path: `${APP_PREFIX_PATH}/relatorios`,
        title: "reports",
        icon: FileSearchOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "titleRequests",
    path: "",
    title: "requests",
    breadcrumb: false,
    submenu: [
      {
        key: "eventMonitor2",
        path: `${APP_PREFIX_PATH}/monitor-de-eventos`,
        title: "eventMonitor",
        icon: AlertOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboardRequests",
        path: `${APP_PREFIX_PATH}/dashboard/solicitacoes`,
        title: "dashboardRequests",
        icon: BarChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "availableItems",
        path: `${APP_PREFIX_PATH}/itens-disponiveis`,
        title: "availableItems",
        icon: UnorderedListOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },

  {
    key: "users",
    path: "",
    title: "management",
    breadcrumb: false,
    submenu: [
      {
        key: "sectorsAndRooms",
        path: `${APP_PREFIX_PATH}/setores-e-quartos`,
        title: "sectorsAndRooms",
        icon: BorderInnerOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "users",
        path: `${APP_PREFIX_PATH}/usuarios`,
        title: "users",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "rolesAndPermissions",
        path: `${APP_PREFIX_PATH}/cargos-e-permissoes`,
        title: "rolesAndPermissions",
        icon: ApartmentOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
