import { CheckOutlined, DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import lang from "assets/data/language.data.json";
import { useTheme } from "stores/theme.store";

function getLanguageDetail(locale) {
  const language = lang.find((elm) => elm.langId === locale);
  return language || {};
}

const SelectedLanguage = ({ locale }) => {
  const language = getLanguageDetail(locale);

  if (!language || !language.langName || !language.icon) {
    return (
      <div className="d-flex align-items-center">
        <span>Language not found</span>
      </div>
    );
  }

  const { langName, icon } = language;

  return (
    <div className="d-flex align-items-center">
      <img
        style={{ maxWidth: "20px" }}
        src={`/img/flags/${icon}.png`}
        alt={langName}
      />
      <span className="font-weight-semibold ml-2">
        {langName} <DownOutlined className="font-size-xs" />
      </span>
    </div>
  );
};

export const NavLanguage = ({ configDisplay }) => {
  const { onLocaleChange, locale } = useTheme();

  const handleLocaleChange = (langId) => {
    if (locale !== langId) {
      onLocaleChange(langId);
    }
  };

  const languageOption = (
    <Menu>
      {lang.map((elm) => (
        <Menu.Item
          key={elm.langId}
          className={
            locale === elm.langId ? "ant-dropdown-menu-item-active" : ""
          }
          onClick={() => handleLocaleChange(elm.langId)}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <img
                style={{ maxWidth: "20px" }}
                src={`/img/flags/${elm.icon}.png`}
                alt={elm.langName}
              />
              <span className="font-weight-normal ml-2">{elm.langName}</span>
            </div>
            {locale === elm.langId && (
              <CheckOutlined className="text-success" />
            )}
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={languageOption} trigger={["click"]}>
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <SelectedLanguage locale={locale} />
      </a>
    </Dropdown>
  );
};

export default NavLanguage;
