import React from "react";
import { Link } from "react-router-dom";
import { Menu, Grid, Typography } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from "utils";
import { useTheme } from "stores/theme.store";
import { AbilityContext, Can } from "views";
import { navigationAbility } from "configs/DefineAbilityConfig";
import { useAbility } from "@casl/react";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
    navCollapsed,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const ability = useAbility(AbilityContext);
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  return (
    <>
      <Menu
        theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
        mode="inline"
        style={{ height: "100%", borderRight: 0 }}
        defaultSelectedKeys={[routeInfo?.key]}
        defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
        className={hideGroupTitle ? "hide-group-title" : ""}
      >
        {navigationConfig.map((menu) =>
          menu.submenu.length > 0 ? (
            menu?.title ? (
              <Can I="view" a={navigationAbility[menu.key]} key={menu.key}>
                <Menu.ItemGroup
                  key={menu.key}
                  title={setLocale(localization, menu.title)}
                >
                  {menu.submenu.map((subMenuFirst) =>
                    subMenuFirst.submenu.length > 0 ? (
                      <SubMenu
                        icon={
                          subMenuFirst.icon ? (
                            <Icon type={subMenuFirst?.icon} />
                          ) : null
                        }
                        key={subMenuFirst.key}
                        title={setLocale(localization, subMenuFirst.title)}
                      >
                        {subMenuFirst.submenu.map((subMenuSecond) => (
                          <Menu.Item key={subMenuSecond.key}>
                            {subMenuSecond.icon ? (
                              <Icon type={subMenuSecond?.icon} />
                            ) : null}
                            <span>
                              {setLocale(localization, subMenuSecond.title)}
                            </span>
                            <Link
                              onClick={() => closeMobileNav()}
                              to={subMenuSecond.path}
                            />
                          </Menu.Item>
                        ))}
                      </SubMenu>
                    ) : (
                      <Menu.Item
                        key={subMenuFirst.key}
                        style={{
                          display: ability.can(
                            "view",
                            navigationAbility[subMenuFirst.key]
                          )
                            ? "block"
                            : "none",
                          paddingLeft: "24px",
                        }}
                      >
                        <Can I="view" a={navigationAbility[subMenuFirst.key]}>
                          {subMenuFirst.icon ? (
                            <Icon type={subMenuFirst.icon} />
                          ) : null}
                          <span>
                            {setLocale(localization, subMenuFirst.title)}
                          </span>
                          <Link
                            onClick={() => closeMobileNav()}
                            to={subMenuFirst.path}
                          />
                        </Can>
                      </Menu.Item>
                    )
                  )}
                </Menu.ItemGroup>
              </Can>
            ) : null
          ) : (
            <Menu.Item
              key={menu.key}
              style={{
                display: ability.can("view", navigationAbility[menu.key])
                  ? "block"
                  : "none",
              }}
            >
              <Can I="view" a={navigationAbility[menu.key]}>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu?.title)}</span>
                {menu.path ? (
                  <Link onClick={() => closeMobileNav()} to={menu.path} />
                ) : null}
              </Can>
            </Menu.Item>
          )
        )}
      </Menu>
      {!navCollapsed && (
        <Typography
          style={{
            position: "absolute",
            bottom: "5px",
            paddingLeft: "24px",
            paddingBottom: "10px",
            color: "#1a335399",
            textAlign: "initial",
          }}
        >
          {`Versão: ${process.env.REACT_APP_VERSION || "1.0.0"}`}
        </Typography>
      )}
    </>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  popupClassName="top-nav-menu"
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  const { sideNavTheme, topNavColor, onMobileNavToggle } = useTheme();
  const p = { ...props, sideNavTheme, topNavColor, onMobileNavToggle };
  return p.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...p} />
  ) : (
    <TopNavContent {...p} />
  );
};

export default MenuContent;
