import { notification } from "antd";
import { socket, socketCalls } from "configs/SocketConfig";
import { useEffect } from "react";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { useAuth } from "stores/auth.store";
import { useTheme } from "stores/theme.store";
import Views from "./views";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const textConnection = {
  "pt-BR": "Conectado com sucesso!",
  "en-US": "Successfully connected!",
  "es-ES": "Conectado con éxito!",
};

function App() {
  const locale = localStorage.getItem("locale") || "es-ES";
  const messages = AppLocale[locale.split("-")[0]].messages;

  const { token, setSocketConnection, userId } = useAuth();
  const { currentTheme, locale: localeTheme } = useTheme();
  const currentAppLocale = AppLocale[localeTheme];

  useEffect(() => {
    if (token && userId) {
      socket.connect();
      socketCalls.connect();
      socket.on("connect_response", (data) => {
        if (data) {
          setSocketConnection(true);
          notification.success({
            message: textConnection[currentAppLocale.locale],
          });
        }
      });
      socket.on("connect_error", (err) => {
        console.log(`Erro ao conectar ao socket: ${err}`);
      });
      return () => {
        socket.disconnect();
        socketCalls.disconnect();
        setSocketConnection(false);
      };
    }
  }, [socket]);

  return (
    <div className="App">
      <IntlProvider locale={locale} messages={messages}>
        <ThemeSwitcherProvider
          themeMap={themes}
          defaultTheme={currentTheme === "dark" ? "dark" : "light"}
          insertionPoint="styles-insertion-point"
        >
          <Router>
            <Switch>
              <Route path="/" component={Views} />
            </Switch>
          </Router>
        </ThemeSwitcherProvider>
      </IntlProvider>
    </div>
  );
}

export default App;
