import { useState } from "react";

import { SettingOutlined } from "@ant-design/icons";
import { Drawer, Menu } from "antd";
import ThemeConfigurator from "./ThemeConfigurator";
import { FormattedMessage } from "react-intl";

const NavPanel = () => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Menu mode="horizontal" className="mt-1">
        <Menu.Item key="panel" onClick={() => setVisible(true)}>
          <span>
            <SettingOutlined className="nav-icon mr-0" />
          </span>
        </Menu.Item>
      </Menu>

      <Drawer
        title={<FormattedMessage id="configurations.title" />}
        placement={"right"}
        width={350}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <ThemeConfigurator />
      </Drawer>
    </>
  );
};
export default NavPanel;
