import React from "react";
import { APP_NAME } from "configs/AppConfig";
import { FormattedMessage } from "react-intl";
export default function Footer() {
  return (
    <footer className="footer">
      <span>
        <FormattedMessage id="copyRight" /> &copy;{" "}
        {`${new Date().getFullYear()}`}{" "}
        <span className="font-weight-semibold">{`${APP_NAME}`}</span>{" "}
        <FormattedMessage id="copyRight.text" />
      </span>
    </footer>
  );
}
