import { useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";

import { Button, Image, Modal, Switch } from "antd";
import { CheckOutlined, LinkOutlined } from "@ant-design/icons";
import QRCode from "qrcode";

import { useTheme } from "stores/theme.store";

import NavLanguage from "./NavLanguage";
import { FormattedMessage } from "react-intl";

const ListOption = ({ name, selector, disabled, vertical }) => (
  <div
    className={`my-4 ${
      vertical ? "" : "d-flex align-items-center justify-content-between"
    }`}
  >
    <div
      className={`${disabled ? "opacity-0-3" : ""} ${vertical ? "mb-3" : ""}`}
    >
      {name}
    </div>
    <div>{selector}</div>
  </div>
);

export const ThemeConfigurator = () => {
  const [modalQrCode, setModalQrCode] = useState(false);
  const [qrCode, setQrCode] = useState(null);
  const [copySuccess, setCopySuccess] = useState("");

  const { onHeaderNavColorChange, onSwitchTheme, currentTheme } = useTheme();

  const { switcher, themes } = useThemeSwitcher();

  const toggleTheme = (isChecked) => {
    onHeaderNavColorChange("");
    const changedTheme = isChecked ? "dark" : "light";
    onSwitchTheme(changedTheme);
    switcher({ theme: themes[changedTheme] });
  };

  const toggleModalQrCode = () => {
    setModalQrCode(!modalQrCode);
  };

  const generateQRCode = async (data) => {
    try {
      const qrCodeDataURL = await QRCode.toDataURL(data);
      setQrCode(qrCodeDataURL);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="mb-5">
        <ListOption
          name={<FormattedMessage id="configurations.text.connect" />}
          selector={
            <Button
              onClick={() => {
                generateQRCode(`${process.env.REACT_APP_ID_HOSPITAL}`);
                toggleModalQrCode();
              }}
              type="primary"
            >
              <FormattedMessage id="configurations.button.connect" />
            </Button>
          }
        />
        <ListOption
          name={<FormattedMessage id="configurations.mode.dark" />}
          selector={
            <Switch checked={currentTheme === "dark"} onChange={toggleTheme} />
          }
        />

        <ListOption
          name={<FormattedMessage id="configurations.language" />}
          selector={
            <NavLanguage />
          }
        />
       

        <Modal
          title="Conectar colaborador"
          visible={modalQrCode}
          onCancel={toggleModalQrCode}
          footer={null}
        >
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p>
              <FormattedMessage id="configurations.modal.text" />
            </p>

            <Image
              src={qrCode ? qrCode : "https://via.placeholder.com/150"}
              width={250}
              className="d-flex align-items-center justify-content-center"
              preview={false}
            />

            <Button
              onClick={() => {
                navigator.clipboard.writeText(
                  process.env.REACT_APP_ID_HOSPITAL
                );
                setCopySuccess("Copiado!");
                setTimeout(() => {
                  setCopySuccess("");
                }, 5000);
              }}
              className="mt-3"
              icon={copySuccess ? <CheckOutlined /> : <LinkOutlined />}
            >
              {copySuccess ? (
                <FormattedMessage id="configurations.modal.codeCopied" />
              ) : (
                <FormattedMessage id="configurations.modal.copyCode" />
              )}
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ThemeConfigurator;
