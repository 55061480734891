import enLang from "./entries/en_US";
import zhLang from "./entries/zh_CN";
import frLang from "./entries/fr_FR";
import jaLang from "./entries/ja_JP";
import esLang from "./entries/es_Es";
import ptLang from "./entries/pt_BR";

const AppLocale = {
  es: esLang,
  pt: ptLang,
  en: enLang,
  zh: zhLang,
  fr: frLang,
  ja: jaLang,
};

export default AppLocale;
