import { AbilityBuilder, createMongoAbility } from "@casl/ability";

export function defineAbilityFor(user) {
  const { can, build } = new AbilityBuilder(createMongoAbility);

  if (user?.employeeRole === "ENFERMAGEM") {
    can("view", "EventMonitorScreen");

    can(["view", "all"], "NursingCardOrder");
    can("view", "CallsCard");
    can("view", "titleNursing");
  }
  if (user?.employeeRole === "COZINHA") {
    can("view", "EventMonitorScreen");

    can("view", "DefaultCardOrder");
    can("view", "titleRequests");
  }
  if (user?.employeeRole === "HOTELARIA") {
    can("view", "EventMonitorScreen");

    can("view", "DefaultCardOrder");
    can("view", "titleRequests");
  }
  if (user?.employeeRole === "LIMPEZA") {
    can("view", "EventMonitorScreen");

    can("view", "DefaultCardOrder");
    can("view", "titleRequests");
  }
  if (user?.employeeRole === "MANUTENCAO") {
    can("view", "EventMonitorScreen");
    can("view", "MaintenanceScreen");

    can("view", "DefaultCardOrder");
    can("view", "CallsCard");
    can("view", "titleRequests");
  }
  if (user?.employeeRole === "ADMIN") {
    can("view", "AdminScreen");
    can("view", "EventMonitorScreen");
    can("view", "ItemsScreen");
    can("view", "SectorAndRoomsScreen");
    can("view", "UserScreen");
    can("view", "RolesAndPermissionsScreen");
    can("view", "DashboardCallsScreen");
    can("view", "DashboardRequestsScreen");
    can("view", "ReportsScreen");

    can("view", "NursingCardOrder");
    can("view", "CallsCard");
    can("view", "titleNursing");
    can("view", "titleRequests");
  }

  return build();
}

export const navigationAbility = {
  eventMonitor: "EventMonitorScreen",
  eventMonitor2: "EventMonitorScreen",
  availableItems: "ItemsScreen",
  sectorsAndRooms: "SectorAndRoomsScreen",
  users: "UserScreen",
  rolesAndPermissions: "RolesAndPermissionsScreen",
  dashboardCalls: "DashboardCallsScreen",
  dashboardRequests: "DashboardRequestsScreen",
  reports: "ReportsScreen",
  nursing: "NursingCardOrder",
  titleNursing: "titleNursing",
  titleRequests: "titleRequests",
};
