import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Can } from "views";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          exact
          path={`${APP_PREFIX_PATH}/dashboard/chamados`}
          component={lazy(() => import(`./dashboard/calls`))}
          render={() => (
            <Can I="view" a="DashboardCallsScreen">
              {React.createElement(lazy(() => import(`./dashboard/calls`)))}
            </Can>
          )}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/dashboard/solicitacoes`}
          component={lazy(() => import(`./dashboard/requests`))}
          render={() => (
            <Can I="view" a="DashboardRequestsScreen">
              {React.createElement(lazy(() => import(`./dashboard/requests`)))}
            </Can>
          )}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/monitor-de-eventos`}
          component={lazy(() => import(`./orders`))}
          render={() => (
            <Can I="view" a="EventMonitorScreen">
              {React.createElement(lazy(() => import(`./available-items`)))}
            </Can>
          )}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/itens-disponiveis`}
          render={() => (
            <Can I="view" a="ItemsScreen">
              {React.createElement(lazy(() => import(`./available-items`)))}
            </Can>
          )}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/itens-disponiveis/:uuidCategory`}
          render={() => (
            <Can I="view" a="ItemsScreen">
              {React.createElement(
                lazy(() => import(`./available-items/products-by-category`))
              )}
            </Can>
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/setores-e-quartos`}
          render={() => (
            <Can I="view" a="SectorAndRoomsScreen">
              {React.createElement(lazy(() => import(`./sectors-and-rooms`)))}
            </Can>
          )}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/usuarios`}
          render={() => (
            <Can I="view" a="UserScreen">
              {React.createElement(lazy(() => import(`./users`)))}
            </Can>
          )}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/cargos-e-permissoes`}
          render={() => (
            <Can I="view" a="RolesAndPermissionsScreen">
              {React.createElement(
                lazy(() => import(`./roles-and-permissions`))
              )}
            </Can>
          )}
        />

        <Route
          exact
          path={`${APP_PREFIX_PATH}/relatorios`}
          render={() => (
            <Can I="view" a="ReportsScreen">
              {React.createElement(
                lazy(() => import(`./reports`))
              )}
            </Can>
          )}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/monitor-de-eventos`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
